import { yupResolver } from '@hookform/resolvers/yup'
import {
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { formatISO, parse, parseISO } from 'date-fns'
import { usePutReward } from 'hooks/api/usePutReward'
import { useLayout } from 'hooks/useLayout'
import { useState } from 'react'
import { useSnackbar } from 'stores/useSnackbar'
import { type Reward } from 'types/api'
import {
  formatTypeName,
  shouldValidateEndTime,
  validateEndTime,
} from 'utils/util'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useForm } from 'react-hook-form'
import { date, object, string } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

const RewardSchema = object({
  startDate: date().when('scheduledPromotion', {
    is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
    then: () =>
      date()
        .required('This field requires entry')
        .typeError('This field requires entry'),
  }),
  startTime: string().when('scheduledPromotion', {
    is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
    then: () =>
      string()
        .required('This field requires entry')
        .matches(
          /^(0\d|1[0-2]):[0-5]\d [APap][mM]$/,
          'Invalid time format. Please enter a valid time.'
        ),
  }),
  endDate: date()
    .nullable()
    .typeError('This date is invalid')
    .transform((currentValue, originalValue, context) => {
      if (context.isType(currentValue)) {
        return currentValue
      }
      if (originalValue === '') {
        return null
      }
      if (originalValue === 'MM/DD/YYYY') {
        return null
      }
      try {
        parse(originalValue, 'MM/DD/YYYY', new Date())
      } catch (error) {
        return new Date('')
      }
      return currentValue
    })
    .when(
      ['scheduledPromotion', 'startDate'],
      ([scheduledPromotion, startDate], schema) => {
        if (
          scheduledPromotion &&
          startDate instanceof Date &&
          isFinite(+startDate)
        ) {
          return schema.min(
            startDate,
            'End date should not be before start date'
          )
        }
        return schema
      }
    ),
  endTime: string().when(
    ['startDate', 'startTime', 'endDate'],
    ([startDate, startTime, endDate], schema) => {
      return schema.test({
        name: 'endTime',
        message: 'This field is required',
        test: (value) => {
          const timeRegex = /^(0?[1-9]|1[0-2]):[0-5]\d (AM|PM)$/
          if (
            value &&
            endDate instanceof Date &&
            isFinite(+endDate) &&
            !timeRegex.test(value)
          ) {
            return false
          }

          if (endDate instanceof Date && value?.toString().length === 0) {
            return false
          }

          if (value && endDate instanceof Date && isFinite(+endDate)) {
            return (
              shouldValidateEndTime(startDate, startTime, endDate, value) &&
              validateEndTime(startDate, startTime, endDate, value)
            )
          }

          return true
        },
      })
    }
  ),
})

export const EditRewardItem = ({
  isModalOpen,
  toggleModalIsOpen,
  reward,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  reward: Reward
}) => {
  const formMethods = useForm({
    resolver: yupResolver(RewardSchema),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'
  const alphanumericRegex = /^[\w-]*$/
  const [itemType, setItemType] = useState(reward.type || 'LocationOffer')
  const [vendorItemName, setVendorItemName] = useState(
    reward.vendorItemName ?? ''
  )
  const [rewardItemName, setRewardItemName] = useState(reward.name)

  const [sku, setSKU] = useState(reward.sku ?? '')
  const [skuError, setSKUError] = useState('')
  const [description, setDescription] = useState(reward.description ?? '')
  const [giftCard, setGiftCard] = useState(reward.giftCard ?? false)
  const [messageError, setMessageError] = useState('')
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const [selectedScheduledPromotion, setSelectedScheduledPromotion] = useState(
    reward.startDate ? 'Yes' : 'No'
  )

  const handleRewardNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRewardItemName(e.target.value)
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    setItemType(e.target.value as Reward['type'])
  }
  const handleVendorItemNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setVendorItemName(e.target.value)
  }
  const handleSkuChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!alphanumericRegex.test(e.target.value)) {
      setSKUError('Alphanumeric characters only.')
    } else {
      setSKUError('')
    }
    setSKU(e.target.value)
  }
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(e.target.value)
  }

  const startDate = reward.startDate ? parseISO(reward.startDate) : null
  const endDate = reward.endDate ? parseISO(reward.endDate) : null

  const putRewardMutation = usePutReward({
    id: Number(reward.id),
    onSuccess: () => {
      setSnackbarMessage('Reward item successfully updated')
      toggleModalIsOpen()
    },
    onError: (error, _) => {
      setMessageError(error.message)
    },
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    if (skuError) {
      setMessageError('Please correct the errors below.')
    } else {
      setMessageError('')
      putRewardMutation.mutate({
        name: rewardItemName,
        type: itemType,
        vendorItemName,
        sku,
        description,
        giftCard,
        startDate:
          selectedScheduledPromotion === 'Yes' &&
          data.startDate &&
          data.startTime
            ? formatISO(parse(data.startTime, 'hh:mm a', data.startDate))
            : null,
        endDate:
          selectedScheduledPromotion === 'Yes' && data.endDate && data.endTime
            ? formatISO(parse(data.endTime, 'hh:mm a', data.endDate))
            : null,
      })
    }
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <form onSubmit={handleSubmitWrapper}>
        <ModalFullHeader toggleIsOpen={toggleModalIsOpen} title={reward.name} />
        <ModalFullBody heightModal={heightModal}>
          <div className="container flex flex-col">
            <Typography color={'red'}>{messageError}</Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
              <Typography>
                Reward Item Name <span style={{ color: '#CC2027' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                placeholder="Reward Item Name"
                value={rewardItemName}
                onChange={handleRewardNameChange}
                sx={{ maxWidth: 'none' }}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
              <Typography>
                Reward Item Type <span style={{ color: '#CC2027' }}>*</span>
              </Typography>
              <Select
                fullWidth
                required={true}
                variant="outlined"
                displayEmpty={true}
                renderValue={(selectedType: string) =>
                  formatTypeName(selectedType) ?? 'Select Type'
                }
                value={itemType}
                onChange={handleSelectChange}
              >
                <MenuItem value="LocationOffer">Location Offer</MenuItem>
                <MenuItem value="JJStoreItem">J&J Store</MenuItem>
                <MenuItem value="MatchPlay">Match Play</MenuItem>
              </Select>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="LicensedEstablishment.scheduledPromotion"
                  required
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Do you want to schedule this Reward Item?
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  inputProps={{ id: 'scheduledBoolean' }}
                  data-testid="scheduledBoolean"
                  value={selectedScheduledPromotion}
                  onChange={(e) =>
                    setSelectedScheduledPromotion(e.target.value)
                  }
                  sx={{ width: '100%', maxWidth: '520px' }}
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </Grid>
            </div>
            {selectedScheduledPromotion === 'Yes' && (
              <Grid container pt={1}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="startDate"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Start Date/Time
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={2.45}>
                  <HookableDateField
                    {...register('startDate')}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.startDate),
                      },
                    }}
                    defaultValue={startDate}
                    value={startDate}
                  />
                  {errors.startDate?.message && (
                    <FormFieldErrorMessage message={errors.startDate.message} />
                  )}
                </Grid>
                <Grid item xs={12} md={0.2}></Grid>
                <Grid item xs={12} md={2.45}>
                  <HookableTimeField
                    defaultValue={startDate as string | null}
                    {...register('startTime')}
                    slotProps={{
                      textField: {
                        required: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.startTime),
                      },
                    }}
                  />
                  {errors.startTime?.message && (
                    <FormFieldErrorMessage message={errors.startTime.message} />
                  )}
                </Grid>
              </Grid>
            )}

            {selectedScheduledPromotion === 'Yes' && (
              <Grid container pt={1} pb={3}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="endDate"
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    End Date/Time
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={2.45}>
                  <HookableDateField
                    {...register('endDate')}
                    defaultValue={endDate}
                    value={endDate}
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.endDate),
                      },
                    }}
                  />
                  {errors.endDate?.message && (
                    <FormFieldErrorMessage message={errors.endDate.message} />
                  )}
                </Grid>
                <Grid item xs={12} md={0.2}></Grid>
                <Grid item xs={12} md={2.45}>
                  <HookableTimeField
                    {...register('endTime')}
                    defaultValue={endDate as string | null}
                    slotProps={{
                      textField: {
                        required: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.endTime),
                      },
                    }}
                    sx={{ width: '100%' }}
                  />
                  {errors.endTime?.message && (
                    <FormFieldErrorMessage message={errors.endTime.message} />
                  )}
                </Grid>
              </Grid>
            )}

            {itemType === 'JJStoreItem' && (
              <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
                <Typography>Vendor Item Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Vendor Item Name"
                  value={vendorItemName}
                  onChange={handleVendorItemNameChange}
                  sx={{
                    maxWidth: 'none',
                  }}
                />
              </div>
            )}
            {itemType === 'JJStoreItem' && (
              <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
                <Typography>SKU</Typography>
                <TextField
                  fullWidth
                  placeholder="SKU"
                  value={sku}
                  onChange={handleSkuChange}
                  helperText={skuError}
                  error={!!skuError}
                  sx={{
                    maxWidth: 'none',
                  }}
                />
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2">
              <Typography>Description</Typography>
              <TextField
                fullWidth
                placeholder="Describe the item"
                minRows={4}
                multiline
                value={description}
                inputProps={{
                  maxLength: 500,
                  overflow: 'scroll !important',
                }}
                onChange={handleDescriptionChange}
                sx={{
                  maxWidth: 'none',
                  '& .MuiInputBase-input': {
                    overflow: 'auto !important',
                    maxHeight: '125px !important',
                    height: '125px !important',
                  },
                }}
              />
            </div>
          </div>
          {itemType === 'JJStoreItem' && (
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mt-6">
              <Typography></Typography>
              <div className="flex container items-center">
                <Checkbox
                  checked={giftCard}
                  onChange={(e) => setGiftCard(e.target.checked)}
                />
                <div className="mt-1">
                  <Typography>eGift Card</Typography>
                </div>
              </div>
            </div>
          )}
        </ModalFullBody>
        <ModalFullFooter>
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            active={putRewardMutation.isPending}
            type="submit"
            variant="contained"
          >
            Save
          </ActivityButton>
        </ModalFullFooter>
      </form>
    </Modal>
  )
}
