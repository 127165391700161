import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { useApiClient } from 'hooks/useApiClient'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'stores/useSnackbar'
import { object, string, date } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useState } from 'react'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { shouldValidateEndTime, validateEndTime } from 'utils/util'
import { formatISO, parse } from 'date-fns'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
interface FormData {
  description?: string | undefined
  scheduledReward?: string | undefined
  startDate?: Date | undefined
  startTime?: string | undefined
  endDate?: Date | null | undefined
  endTime?: string | undefined
  name?: string
}

const RewardItemSchema = object({
  name: string().required('Must enter a reward item name to create a reward'),
  description: string().optional(),
  scheduledReward: string(),
  startDate: date().when('scheduledPromotion', {
    is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
    then: () =>
      date()
        .required('This field requires entry')
        .typeError('This field requires entry'),
  }),
  startTime: string().when('scheduledPromotion', {
    is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
    then: () =>
      string()
        .required('This field requires entry')
        .matches(
          /^(0\d|1[0-2]):[0-5]\d [APap][mM]$/,
          'Invalid time format. Please enter a valid time.'
        ),
  }),
  endDate: date()
    .nullable()
    .typeError('This date is invalid')
    .transform((currentValue, originalValue, context) => {
      if (context.isType(currentValue)) {
        return currentValue
      }
      if (originalValue === '') {
        return null
      }
      if (originalValue === 'MM/DD/YYYY') {
        return null
      }
      try {
        parse(originalValue, 'MM/DD/YYYY', new Date())
      } catch (error) {
        return new Date('')
      }
      return currentValue
    })
    .when(
      ['scheduledPromotion', 'startDate'],
      ([scheduledPromotion, startDate], schema) => {
        if (
          scheduledPromotion &&
          startDate instanceof Date &&
          isFinite(+startDate)
        ) {
          return schema.min(
            startDate,
            'End date should not be before start date'
          )
        }
        return schema
      }
    ),
  endTime: string().when(
    ['startDate', 'startTime', 'endDate'],
    ([startDate, startTime, endDate], schema) => {
      return schema.test({
        name: 'endTime',
        message: 'This field is required',
        test: (value) => {
          const timeRegex = /^(0?[1-9]|1[0-2]):[0-5]\d (AM|PM)$/
          if (
            value &&
            endDate instanceof Date &&
            isFinite(+endDate) &&
            !timeRegex.test(value)
          ) {
            return false
          }

          if (endDate instanceof Date && value?.toString().length === 0) {
            return false
          }

          if (value && endDate instanceof Date && isFinite(+endDate)) {
            return (
              shouldValidateEndTime(startDate, startTime, endDate, value) &&
              validateEndTime(startDate, startTime, endDate, value)
            )
          }

          return true
        },
      })
    }
  ),
})

export const RewardItemsNewPage = () => {
  const { post } = useApiClient()
  const navigate = useNavigate()
  const setMessage = useSnackbar((state) => state.setMessage)
  const [selectedScheduledReward, setSelectedScheduledReward] = useState('No')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RewardItemSchema),
  })

  const mutate = useMutation({
    mutationKey: ['rewards/create'],
    mutationFn: async ({
      name,
      startDate,
      startTime,
      endDate,
      endTime,
    }: FormData) => {
      const { data } = await post('/admin/rewards', {
        params: { header: undefined },
        body: {
          name: String(name),
          type: 'LocationOffer',
          description: '',
          startDate: startDate
            ? formatISO(parse(String(startTime), 'hh:mm a', startDate))
            : null,
          endDate: endDate
            ? formatISO(parse(String(endTime), 'hh:mm a', endDate))
            : null,
        },
      })
      return data
    },
    onSuccess: (data) => {
      setMessage('Reward Item was successfully created.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate(`/Rewards/RewardItems/${data?.id}`)
    },
    onError: () => {
      setMessage('Error creating reward', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (mutate.isPending) {
    return <ActivityIndicator />
  }

  if (mutate.isError) {
    return <h1>An error occured.</h1>
  }

  const createRewardWrapper = handleSubmit((data) => {
    mutate.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title={'Create a New Reward Item'}
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backText="Reward Items"
          backPath="/Rewards/RewardItems"
          isSecondary={true}
        ></PageHeader>
      }
    >
      <form onSubmit={createRewardWrapper}>
        <Stack spacing={4} maxWidth={500}>
          <TextField
            label="Reward Item Name"
            placeholder="Enter reward item name"
            id="reward-item-name-text-box"
            required
            {...register('name', { required: true })}
            error={isPresent(errors.name)}
          />
          {errors.name?.message && (
            <FormFieldErrorMessage message={errors.name.message} />
          )}

          <Stack direction="column" gap={1}>
            <InputLabel htmlFor={'scheduledBoolean'}>
              Do you want to schedule this Reward Item?
            </InputLabel>
            <Select
              {...register('scheduledReward', { required: true })}
              inputProps={{ id: 'scheduledBoolean' }}
              data-testid="scheduledBoolean"
              error={isPresent(errors.scheduledReward)}
              defaultValue="No"
              value={selectedScheduledReward}
              onChange={(e) => setSelectedScheduledReward(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
            {errors.scheduledReward?.message && (
              <FormFieldErrorMessage message={errors.scheduledReward.message} />
            )}
          </Stack>

          {selectedScheduledReward === 'Yes' && (
            <div>
              <Typography variant="body-2">
                To keep the reward item active, don&apos;t select an end date
                and time.
              </Typography>
              <div>
                <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel required>Start Date</InputLabel>
                    <HookableDateField
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startDate),
                        },
                      }}
                      {...register('startDate')}
                    />
                    {errors.startDate?.message && (
                      <FormFieldErrorMessage
                        message={errors.startDate.message}
                      />
                    )}
                  </div>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel required>Start Time</InputLabel>
                    <HookableTimeField
                      {...register('startTime')}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startTime),
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.startTime?.message && (
                      <FormFieldErrorMessage
                        message={errors.startTime.message}
                      />
                    )}
                  </div>
                </Stack>
              </div>

              <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                <div style={{ flex: 1, width: '100%' }}>
                  <InputLabel>End Date</InputLabel>
                  <HookableDateField
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.endDate),
                      },
                    }}
                    {...register('endDate')}
                  />
                  {errors.endDate?.message && (
                    <FormFieldErrorMessage message={errors.endDate.message} />
                  )}
                </div>
                <div style={{ flex: 1, width: '100%' }}>
                  <InputLabel>End Time</InputLabel>
                  <HookableTimeField
                    {...register('endTime')}
                    slotProps={{
                      textField: {
                        required: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        },
                        error: isPresent(errors.endTime),
                      },
                    }}
                    sx={{ width: '100%' }}
                  />
                  {errors.endTime?.message && (
                    <FormFieldErrorMessage message={errors.endTime.message} />
                  )}
                </div>
              </Stack>
            </div>
          )}

          <div className="pt-6 flex flex-row gap-6">
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/Rewards/RewardItems')
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </div>
        </Stack>
      </form>
    </Page>
  )
}
