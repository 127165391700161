/* eslint-disable no-template-curly-in-string */
import { type components } from 'api/playerPayback/api'
import { date, number, object, string, array, boolean } from 'yup'

export type TypeOfMessage = components['schemas']['CampaignType']
export type ExcludeType = 'LE' | 'Org' | 'CA' | 'PatronsWithCheckins' | 'None'
export type GenderType = 'All' | 'Female' | 'Male' | 'PreferNotToAnswer'

export const LicensedEstablishmentSchema = object().shape({
  campaignId: number().required(),
  licensedEstablishmentId: number().required(),
})

export const CampaignSchema = object().shape(
  {
    typeOfMessage: string<TypeOfMessage>().required(),
    subjectLine: string()
      .required('Subject is required')
      .max(50, 'Subject can be at most ${max} characters'),
    messageContent: string().required('Message is required'),
    linkLabel: string()
      .when('linkUrl', {
        is: (val: string) => val && val.length > 0,
        then: (schema) =>
          schema.required('Link Label is required if Link URL is present'),
        otherwise: (schema) => schema.notRequired(),
      })
      .max(30, 'Link label can be at most ${max} characters'),
    linkUrl: string()
      .when('linkLabel', {
        is: (val: string) => val && val.length > 0,
        then: (schema) =>
          schema.required('Link URL is required if Link Label is present'),
        otherwise: (schema) => schema.notRequired(),
      })
      .max(200, 'Link URL can be at most ${max} characters')
      .test('is-https', 'Link URL must start with "https://"', (value) => {
        // only check for https if value exists
        if (value && value.length > 0) {
          return value.startsWith('https://')
        }
        return true // if the value is empty, skip the test
      }),
    scheduledDate: date().required('Scheduled date is required'),
    licensedEstablishments: array()
      .of(LicensedEstablishmentSchema)
      .when('typeOfMessage', {
        is: 'Location',
        then: (schema) =>
          schema.min(1, 'At least one Licensed Establishment is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    contestId: number().when('typeOfMessage', {
      is: 'Sweepstakes',
      then: (schema) => schema.required('Sweepstake is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    excludeType: string<ExcludeType>().default('None'),
    excludedId: number().when('excludeType', {
      is: (val: string) => val === 'None' || val === 'PatronsWithCheckins',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Select an item to exclude'),
    }),
    corporateAccountId: number().when('typeOfMessage', {
      is: 'Corporate',
      then: (schema) => schema.required('Corporate Account Id is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    tierId: number().when('typeOfMessage', {
      is: 'AllPatronsByTier',
      then: (schema) => schema.required('Tier is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    filterGender: string<GenderType>().default('All'),
    filterAgeRange: string().max(
      5,
      'Age Range can be at most ${max} characters'
    ),
    filterState: string().max(200, 'State can be at most ${max} characters'),
    filterZipCode: string().max(
      500,
      'Zip Code can be at most ${max} characters'
    ),
    filterIncompleteProfiles: boolean().default(false),
  },
  [['linkLabel', 'linkUrl']]
)
